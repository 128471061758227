export const vendor = {
  getAllVendors: {
    url: "vendor/getAll",
    method: "GET",
    params: null,
  },
  getAvailableTribe: {
    url: "admin/tribes/getForVendor",
    method: "GET",
    params: null,
  },
  createVendor: {
    url: "vendor/create",
    method: "POST",
    data: null,
  },
  updateVendor: {
    url: "vendor/update",
    method: "POST",
    data: null,
  },
  deleteVendor: {
    url: "vendor/delete",
    method: "POST",
    data: null,
  },
  removeAssignedTribe: {
    url: "vendor/removeTribe",
    method: "POST",
    data: null,
  },
};
